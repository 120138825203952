import { HolUser } from '../../common/models/hol-user.model';
import { EclSummary } from '../../ecl/models/ecl-summary';
import { HolObject } from '../../common/models/hol-object';

export abstract class OclGroup<T extends HolObject> {
  objectId?: string;
  title?: string;
  createdBy?: HolUser;
  createdAt?: Date;
  items: T[];
  displayInGroupViewStatus: string;
  summary: EclSummary = null;

  crisis: any;

  protected constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.title = parseObject.get('title');
    if (parseObject.get('createdBy')) {
      this.createdBy = new HolUser(parseObject.get('createdBy'));
    }
    this.createdAt = parseObject.get('createdAt');
  }

  protected abstract newItem(parseObject?: Parse.Object): T;

  public get getMostRecentItem(): T {
    return this.items.length > 0 ? this.items.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0] : null;
  }
}
