import { Component, inject, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { FlyIn } from 'src/app/common/models/hol-animations';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { OPSLayoutInfos } from 'src/app/common/store/common.model';
import { TagChangeService } from 'src/app/common/services/tag-change.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { LocalStorageService } from 'src/app/common/services/local-storage.service';
import { CommonService } from 'src/app/common/services/common.service';

import { BaseComponent } from '../../../common/components/base/base.component';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { HolScenario } from '../../../common/models/hol-scenario';
import { TranslateService } from '@ngx-translate/core';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { RolesService } from '../../../common/services/roles.service';
import { OCL_EVENT_MODAL_TOKEN } from '../../modals/ocl-event-modal/ocl-event-modal-tokens';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclEvent } from '../../models/ocl-event.model';
import { OclHelperLink } from '../../models/ocl-helper-link.model';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclHelperLinkService } from '../../services/ocl-helper-link.service/ocl-helper-link.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclScenarioService } from '../../services/ocl-scenario-service/ocl-scenario.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclScenariosStoreManager } from '../../store/scenarios/ocl-scenarios.store-manager';
import { cloneDeep, extend, find, orderBy } from 'lodash';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { OclGroup } from '../../models/ocl-group.model';

@Component({
  selector: 'app-ocl-event-box',
  templateUrl: './ocl-event-box.component.html',
  styleUrls: ['./ocl-event-box.component.scss'],
  animations: [FlyIn],
})
export class OclEventBoxComponent<T extends OclEvent, G extends OclGroup<T>> extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public isReadOnly: boolean;
  @Input() utcDisplay: HolUTCDisplay;
  @Input() isItemsRequired?: boolean = true;
  @Input() linkedIds;
  @Input() eclInfos;
  @Input() airportsCode;
  @Input() userCanEditCards?: boolean;

  public context = 'event';
  public eventsTitle: string;
  public lastDisplayTime: Date;
  public originalScenarios: HolScenario[];
  public scenarioId?: string;
  public searchCriteriasForPipe: string;
  public events: OclEvent[] = [];
  public eventsGrouped: OclEventGroup[] = [];
  public groupsFromApi: OclEventGroup[] = [];
  public emptyEventsGroup: OclEventGroup[] = [];
  public modeLinkIsActivate = false;
  public helperLinkList: OclHelperLink[];
  public bufferHelperLinkId: string[];
  public RolesService = RolesService;
  public objectKeys = Object.keys;
  public scenariosByGroup = {};
  private groupsAvailable: OclEventGroup[] = [];
  private storeLayoutInfos: OPSLayoutInfos;
  private currentTags: HolTag[];
  private ngUnsubscribe: Subject<void> = new Subject();
  private _originalEvents = new BehaviorSubject<OclEvent[]>([]);
  private _eventGroups = new BehaviorSubject<OclEventGroup[]>([]);
  private _modeLinkIsActivate = new BehaviorSubject<boolean>(false);

  protected readonly translate = inject(TranslateService);

  constructor(
    protected helperLinkListService: OclHelperLinkService,
    protected optionsService: OclOptionsService,
    protected flightsStatusService: OclFlightStatusService,
    protected readonly commonService: CommonService,
    protected readonly localStorage: LocalStorageService,
    protected notificationsService: OclNotificationsService,
    protected dialog: MatDialog,
    protected groupService: OclGroupService,
    protected scenarioService: OclScenarioService,
    protected occEventsStoreManager: OclEventsStoreManager,
    public moduleConfig: ModuleConfigService,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
    protected commonStoreManager: CommonStoreManager,
    protected tagChangeService: TagChangeService,
    protected scenariosStoreManager: OclScenariosStoreManager,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
  ) {
    super();
    this.lastDisplayTime = this.localStorage.getOccDashboardLastDisplayTime() || new Date();

    this.helperLinkListService.getLinks(this.bufferHelperLinkId, !!this.bufferHelperLinkId).then(l => {
      this.helperLinkList = l;
      this.bufferHelperLinkId = this.helperLinkList.map(el => el.objectId);
    });
  }

  get originalEventsInput() {
    return this._originalEvents.getValue();
  }

  @Input()
  set originalEventsInput(value) {
    this._originalEvents.next(value);
  }

  get searchCriteriasdata() {
    return this._searchCriterias.getValue();
  }

  get modeLinkIsActivateInput() {
    return this._modeLinkIsActivate.getValue();
  }

  @Input()
  set modeLinkIsActivateInput(value) {
    this._modeLinkIsActivate.next(value);
  }

  get eventGroupsInput() {
    return this._eventGroups.getValue();
  }

  @Input()
  set eventGroupsInput(value: OclEventGroup[]) {
    this._eventGroups.next(value);
  }

  private _searchCriterias = new BehaviorSubject<string>('');

  @Input()
  set searchCriterias(value) {
    this._searchCriterias.next(value);
  }

  get enableViewGroup(): boolean {
    return (
      (!this.currentTags || this.currentTags.length === 0) &&
      this.searchCriteriasForPipe.length === 0 &&
      (this.groupsAvailable.length || this.emptyEventsGroup) &&
      !this.modeLinkIsActivate
    );
  }

  get isSetGroups(): boolean {
    return this.enableViewGroup ? this.storeLayoutInfos.groups.eventsGroupEnabled : false;
  }

  set isSetGroups(value: boolean) {
    this.commonStoreManager.updateLayoutGroups({
      eventsGroupEnabled: value,
    });
  }

  public get localHourOnly(): boolean {
    return this.utcDisplay === HolUTCDisplay.FORCE_LOCAL;
  }

  public ngOnInit() {
    this.eventsTitle = this.optionsService.getEventsTitle()
      ? this.optionsService.getEventsTitle()
      : this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.TITLE');

    combineLatest([this._originalEvents, this._eventGroups])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([originalEvents, eventGroups]) => {
        this.events = originalEvents ? cloneDeep(originalEvents) : [];
        this.events = orderBy(this.events, item => {
          if (this.getNextInfo(item)) {
            return this.getNextInfo(item).nextInfoTime;
            // not only occEvents => cant use obj.getNextInfo
          }
          return null; // items without nextInfo // all nextInfo is in done state
        });
        this.groupsFromApi = eventGroups ? eventGroups : [];
        this.groupsAvailable =
          eventGroups && (eventGroups.length || [eventGroups].length)
            ? this.groupService.generateGroupAvailable(cloneDeep(eventGroups), this.events)
            : [];
        this.eventsGrouped = this.generateEventsGrouped(this.groupsAvailable, this.events);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        if (eventGroups && (eventGroups.length || [eventGroups].length)) {
          this.emptyEventsGroup = eventGroups
            .filter(
              o =>
                !this.groupsAvailable
                  .map(ga => {
                    return ga.objectId;
                  })
                  .includes(o.objectId),
            )
            .map(l => {
              l.items = [];
              return l;
            });
        }
      });

    this.tagChangeService.tagsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tags => (this.currentTags = cloneDeep(tags)));

    this._searchCriterias.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cristerias => {
      this.searchCriteriasForPipe = cloneDeep(cristerias);
    });

    this._modeLinkIsActivate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.modeLinkIsActivate = cloneDeep(res);
      if (res) {
        this.onScenarioChanged('');
      }
    });

    this.scenariosStoreManager.scenariosState
      .pipe(take(1))
      .toPromise()
      .then(scenarios => {
        this.originalScenarios = orderBy(scenarios, 'code');

        if (this.moduleConfig.config.scenariosByGroup) {
          this.scenariosByGroup = this.scenarioService.orderByGroupingCode(this.originalScenarios);
        }
      })
      .catch(error => console.log(error));

    this.commonStoreManager.layoutInfos
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(layoutInfosData => (this.storeLayoutInfos = layoutInfosData));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public async openAddModal() {
    this.dialog
      .open(this.eventModal, {
        data: {
          notifications: await this.notificationsService.getAll(),
          fromLogbook: null,
          fromDecision: null,
          isUtc: utcEval(this.utcDisplay, this.isReadOnly),
          scenarios: this.originalScenarios,
          airportsCode: this.airportsCode,
        },
      })
      .afterClosed()
      .subscribe(event => {
        if (event) {
          this.flightsStatusService.setNeedsUpdate(true);
        }
      });
  }

  public onEventUpdated(updatedEvent): void {
    if (updatedEvent) {
      // STORE
      this.occEventsStoreManager.updateOneEvent(extend(find(this.events, { objectId: updatedEvent.objectId }), updatedEvent));
    }
  }

  public openGroupModal(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: null,
        logBookItems: null,
        events: this.events,
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: this.groupsFromApi,
        type: 'EVENT',
        isItemsRequired: this.isItemsRequired,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.groupService.fetchNewDataEventGroup().then();
    });
  }

  public isSeen(item): any {
    return item && item.createdAt ? this.commonService.isSeen(item, this.lastDisplayTime) : true;
  }

  public getNextInfo(event): HolNextInfo {
    return find(orderBy(event.infos, 'nextInfoTime', 'asc'), (item: HolNextInfo) => !item.done);
  }

  public getNextInfoTime(event): any {
    const nextInfo = this.getNextInfo(event);
    return nextInfo ? nextInfo.nextInfoTime : false;
  }

  public onScenarioChanged(scenarioId: string): void {
    const scenario = find(this.originalScenarios, sc => scenarioId === sc.objectId);
    scenario ? (this.scenarioId = scenario.objectId) : (this.scenarioId = null);
    //   this._searchCriterias.next(this.searchCriteriasdata);
  }

  public goToTemplateHelper(link): void {
    window.open(link, '_blank');
  }

  private generateEventsGrouped(group: OclEventGroup[], events: OclEvent[]): OclEventGroup[] {
    return orderBy(
      this.groupService.generateGroupForDisplay(group, events, 'EVENT'),
      item => {
        if (item.items[0].customCreatedAt) {
          return item.items[0].customCreatedAt;
        } else if (item.items[0].createdAt) {
          return item.items[0].createdAt;
        }
        return null;
      },
      'asc',
    );
  }
}
